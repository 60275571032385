import { TestsStatusQuery } from './API.service';
import { APIService } from 'app/API.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  test: TestsStatusQuery = {};

  constructor(private api: APIService) {

  }

  public getUserTestInfo(): Promise<{
    test: string;
    done: boolean;
    questions: number;
  }> {
    return this.api.TestsStatus().then(data => {
      console.log(data);
      this.test = data;
      let test: any = {};
      for(let key of Object.keys(data)) {
        if(!data[key].done) {
          test = {...data[key], test: key };
          break;
        }
      }
      return test;
    });
  }
}
