import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of , BehaviorSubject , from as fromPromise } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AmplifyService } from 'aws-amplify-angular';
import { Auth } from 'aws-amplify';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  public user = new BehaviorSubject<string>('');
  public loggedIn: BehaviorSubject<boolean>;

  constructor(
    private amplifyService: AmplifyService,
    private router: Router,
    private http: HttpClient) {
    this.loggedIn = new BehaviorSubject<boolean>(false);
  }

  public logout() {
    localStorage.clear();
    this.router.navigateByUrl('auth/login');
  }

  public isAuthenticated(): Observable<boolean> {
  return fromPromise(this.amplifyService.auth().currentAuthenticatedUser())
    .pipe(
      map((result: any) => {
        this.user.next(result.username);
        this.loggedIn.next(true);
        return true;
      }),
      catchError(error => {
        return of(false);
      })
    );
  }

  public async forgotPassword(email: string) {
    return await Auth.forgotPassword(email);
  }

  public async changeUserAttributes(updatedValues: any) {
    const user = await this.getCurrentUser();
    return await Auth.updateUserAttributes(user, updatedValues);
  }

  public async getCurrentUser() {
    return await Auth.currentAuthenticatedUser({ bypassCache: true });
  }

  public async forgotPasswordSubmit(email: string, code: string, password: string) {
    return await Auth.forgotPasswordSubmit(email, code, password);
  }

  public async signIn(credentials: any) {
    return await Auth.signIn(credentials.email, credentials.password);
  }

  public async signOut() {
    return await Auth.signOut();
  }

  public async newPassword(newPassword: string, user: string) {
    return Auth.completeNewPassword(user, newPassword, {});
  }

  public async signUp(attributes: any) {
    return Auth.signUp(attributes);
  }

  public async confirmationCode(username, code) {
    return Auth.confirmSignUp(username, code, { forceAliasCreation: true });
  }

  public async resendConfirmationCode(username: string) {
    return Auth.resendSignUp(username);
  }

  public lambdaTrigger() {
    this.getCurrentUser().then(data => {
      this.http.get('https://yd72xlkuaj.execute-api.eu-west-1.amazonaws.com/dev', {
        headers: {
          Authorization: data.signInUserSession.idToken.jwtToken,
          id: data.username
        }
      }).toPromise()
      .then(blob => { console.log(blob); })
      .catch(err => console.log(err));
    }).catch(e => console.log(e));
  }
}

