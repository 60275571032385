// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
   'aws_project_region': 'eu-west-1',
   'aws_cognito_identity_pool_id': 'eu-west-1:22b75915-a027-419f-9350-2635b8de8511',
   'aws_cognito_region': 'eu-west-1',
   'aws_user_pools_id': 'eu-west-1_aq0Uegc5E',
   'aws_user_pools_web_client_id': 'hq5l43u7opj53vsp6mscu86t3',
   'oauth': {},
   'aws_appsync_graphqlEndpoint': 'https://mltnuyre2fhrpi5i2xua5hskfi.appsync-api.eu-west-1.amazonaws.com/graphql',
   'aws_appsync_region': 'eu-west-1',
   'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS'
};


export default awsmobile;


/* code: "0101M",
correct: false,
number: "4",
sequence: ["8"],
test: "test1",
time: "3.066",
user: "e3790b5f-583d-4edf-ad23-f98158f52fb3" */