/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api/lib/types";
import * as Observable from "zen-observable";

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type CreateAnswerInput = {
  id?: string | null;
  user?: string | null;
  test?: string | null;
  number?: string | null;
  code?: string | null;
  answer?: string | null;
  time?: string | null;
  sequence?: Array<string | null> | null;
  correct?: boolean | null;
};

export type ModelAnswerConditionInput = {
  user?: ModelStringInput | null;
  test?: ModelStringInput | null;
  number?: ModelStringInput | null;
  code?: ModelStringInput | null;
  answer?: ModelStringInput | null;
  time?: ModelStringInput | null;
  sequence?: ModelStringInput | null;
  correct?: ModelBooleanInput | null;
  and?: Array<ModelAnswerConditionInput | null> | null;
  or?: Array<ModelAnswerConditionInput | null> | null;
  not?: ModelAnswerConditionInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type Answer = {
  
  id?: string;
  user?: string | null;
  test?: string | null;
  number?: string | null;
  code?: string | null;
  answer?: string | null;
  time?: string | null;
  sequence?: Array<string | null> | null;
  correct?: boolean | null;
  
  
};

export type UpdateAnswerInput = {
  user?: string | null;
  test?: string | null;
  number?: string | null;
  code?: string | null;
  answer?: string | null;
  time?: string | null;
  sequence?: Array<string | null> | null;
  correct?: boolean | null;
};

export type DeleteAnswerInput = {
  id: string;
};

export type CreateQuestions1Input = {
  id?: string | null;
  number?: string | null;
  code?: string | null;
  test?: string | null;
  question?: string | null;
  choices?: Array<string | null> | null;
  timelimit?: string | null;
  variable?: string | null;
};

export type ModelQuestions1ConditionInput = {
  number?: ModelStringInput | null;
  code?: ModelStringInput | null;
  test?: ModelStringInput | null;
  question?: ModelStringInput | null;
  choices?: ModelStringInput | null;
  timelimit?: ModelStringInput | null;
  variable?: ModelStringInput | null;
  and?: Array<ModelQuestions1ConditionInput | null> | null;
  or?: Array<ModelQuestions1ConditionInput | null> | null;
  not?: ModelQuestions1ConditionInput | null;
};

export type Questions1 = {
  
  id?: string;
  number?: string | null;
  code?: string | null;
  test?: string | null;
  question?: string | null;
  choices?: Array<string | null> | null;
  timelimit?: string | null;
  variable?: string | null;
  
  
};

export type UpdateQuestions1Input = {
  number?: string | null;
  code?: string | null;
  test?: string | null;
  question?: string | null;
  choices?: Array<string | null> | null;
  timelimit?: string | null;
  variable?: string | null;
};

export type DeleteQuestions1Input = {
  id: string;
};

export type ResultCheck = {
  
  test1?: TestResults;
  test2?: TestResults;
  test3?: TestResults;
  tovarna?: TestResults;
  test4?: TestResults;
};

export type TestResults = {
  
  done?: boolean | null;
  questions?: number | null;
};

export type ModelAnswerFilterInput = {
  user?: ModelStringInput | null;
  test?: ModelStringInput | null;
  number?: ModelStringInput | null;
  code?: ModelStringInput | null;
  answer?: ModelStringInput | null;
  time?: ModelStringInput | null;
  sequence?: ModelStringInput | null;
  correct?: ModelBooleanInput | null;
  and?: Array<ModelAnswerFilterInput | null> | null;
  or?: Array<ModelAnswerFilterInput | null> | null;
  not?: ModelAnswerFilterInput | null;
};

export type ModelAnswerConnection = {
  
  items?: Array<Answer | null> | null;
  nextToken?: string | null;
};

export type ModelQuestions1FilterInput = {
  number?: ModelStringInput | null;
  code?: ModelStringInput | null;
  test?: ModelStringInput | null;
  question?: ModelStringInput | null;
  choices?: ModelStringInput | null;
  timelimit?: ModelStringInput | null;
  variable?: ModelStringInput | null;
  and?: Array<ModelQuestions1FilterInput | null> | null;
  or?: Array<ModelQuestions1FilterInput | null> | null;
  not?: ModelQuestions1FilterInput | null;
};

export type ModelQuestions1Connection = {
  
  items?: Array<Questions1 | null> | null;
  nextToken?: string | null;
};

export type CreateAnswerMutation = {
  
  id: string;
  user?: string | null;
  test?: string | null;
  number?: string | null;
  code?: string | null;
  answer?: string | null;
  time?: string | null;
  sequence?: Array<string | null> | null;
  correct?: boolean | null;
  
  
};

export type UpdateAnswerMutation = {
  
  id: string;
  user?: string | null;
  test?: string | null;
  number?: string | null;
  code?: string | null;
  answer?: string | null;
  time?: string | null;
  sequence?: Array<string | null> | null;
  correct?: boolean | null;
  
  
};

export type DeleteAnswerMutation = {
  
  id: string;
  user?: string | null;
  test?: string | null;
  number?: string | null;
  code?: string | null;
  answer?: string | null;
  time?: string | null;
  sequence?: Array<string | null> | null;
  correct?: boolean | null;
  
  
};

export type CreateQuestions1Mutation = {
  
  id: string;
  number?: string | null;
  code?: string | null;
  test?: string | null;
  question?: string | null;
  choices?: Array<string | null> | null;
  timelimit?: string | null;
  variable?: string | null;
  
  
};

export type UpdateQuestions1Mutation = {
  
  id: string;
  number?: string | null;
  code?: string | null;
  test?: string | null;
  question?: string | null;
  choices?: Array<string | null> | null;
  timelimit?: string | null;
  variable?: string | null;
  
  
};

export type DeleteQuestions1Mutation = {
  
  id: string;
  number?: string | null;
  code?: string | null;
  test?: string | null;
  question?: string | null;
  choices?: Array<string | null> | null;
  timelimit?: string | null;
  variable?: string | null;
  
  
};

export type TestsStatusQuery = {
  
  test1?: {
    
    done?: boolean | null;
    questions?: number | null;
  } | null;
  test2?: {
    
    done?: boolean | null;
    questions?: number | null;
  } | null;
  test3?: {
    
    done?: boolean | null;
    questions?: number | null;
  } | null;
  tovarna?: {
    
    done?: boolean | null;
    questions?: number | null;
  } | null;
  test4?: {
    
    done?: boolean | null;
    questions?: number | null;
  } | null;
};

export type GetAnswerQuery = {
  
  id: string;
  user?: string | null;
  test?: string | null;
  number?: string | null;
  code?: string | null;
  answer?: string | null;
  time?: string | null;
  sequence?: Array<string | null> | null;
  correct?: boolean | null;
  
  
};

export type ListAnswersQuery = {
  
  items?: Array<{
    
    id: string;
    user?: string | null;
    test?: string | null;
    number?: string | null;
    code?: string | null;
    answer?: string | null;
    time?: string | null;
    sequence?: Array<string | null> | null;
    correct?: boolean | null;
    
    
  } | null> | null;
  nextToken?: string | null;
};

export type GetQuestions1Query = {
  
  id: string;
  number?: string | null;
  code?: string | null;
  test?: string | null;
  question?: string | null;
  choices?: Array<string | null> | null;
  timelimit?: string | null;
  variable?: string | null;
  
  
};

export type ListQuestions1sQuery = {
  
  items?: Array<{
    
    id: string;
    number?: string | null;
    code?: string | null;
    test?: string | null;
    question?: string | null;
    choices?: Array<string | null> | null;
    timelimit?: string | null;
    variable?: string | null;
    
    
  } | null> | null;
  nextToken?: string | null;
};

export type OnCreateAnswerSubscription = {
  
  id: string;
  user?: string | null;
  test?: string | null;
  number?: string | null;
  code?: string | null;
  answer?: string | null;
  time?: string | null;
  sequence?: Array<string | null> | null;
  correct?: boolean | null;
  
  
};

export type OnUpdateAnswerSubscription = {
  
  id: string;
  user?: string | null;
  test?: string | null;
  number?: string | null;
  code?: string | null;
  answer?: string | null;
  time?: string | null;
  sequence?: Array<string | null> | null;
  correct?: boolean | null;
  
  
};

export type OnDeleteAnswerSubscription = {
  
  id: string;
  user?: string | null;
  test?: string | null;
  number?: string | null;
  code?: string | null;
  answer?: string | null;
  time?: string | null;
  sequence?: Array<string | null> | null;
  correct?: boolean | null;
  
  
};

export type OnCreateQuestions1Subscription = {
  
  id: string;
  number?: string | null;
  code?: string | null;
  test?: string | null;
  question?: string | null;
  choices?: Array<string | null> | null;
  timelimit?: string | null;
  variable?: string | null;
  
  
};

export type OnUpdateQuestions1Subscription = {
  
  id: string;
  number?: string | null;
  code?: string | null;
  test?: string | null;
  question?: string | null;
  choices?: Array<string | null> | null;
  timelimit?: string | null;
  variable?: string | null;
  
  
};

export type OnDeleteQuestions1Subscription = {
  
  id: string;
  number?: string | null;
  code?: string | null;
  test?: string | null;
  question?: string | null;
  choices?: Array<string | null> | null;
  timelimit?: string | null;
  variable?: string | null;
  
  
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreateAnswer(
    input: CreateAnswerInput,
    condition?: ModelAnswerConditionInput
  ): Promise<CreateAnswerMutation> {
    const statement = `mutation CreateAnswer($input: CreateAnswerInput!, $condition: ModelAnswerConditionInput) {
        createAnswer(input: $input, condition: $condition) {
          
          id
          user
          test
          number
          code
          answer
          time
          sequence
          correct
          
          
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAnswerMutation>response.data.createAnswer;
  }
  async UpdateAnswer(
    input: UpdateAnswerInput,
    condition?: ModelAnswerConditionInput
  ): Promise<UpdateAnswerMutation> {
    const statement = `mutation UpdateAnswer($input: UpdateAnswerInput!, $condition: ModelAnswerConditionInput) {
        updateAnswer(input: $input, condition: $condition) {
          
          id
          user
          test
          number
          code
          answer
          time
          sequence
          correct
          
          
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAnswerMutation>response.data.updateAnswer;
  }
  async DeleteAnswer(
    input: DeleteAnswerInput,
    condition?: ModelAnswerConditionInput
  ): Promise<DeleteAnswerMutation> {
    const statement = `mutation DeleteAnswer($input: DeleteAnswerInput!, $condition: ModelAnswerConditionInput) {
        deleteAnswer(input: $input, condition: $condition) {
          
          id
          user
          test
          number
          code
          answer
          time
          sequence
          correct
          
          
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAnswerMutation>response.data.deleteAnswer;
  }
  async CreateQuestions1(
    input: CreateQuestions1Input,
    condition?: ModelQuestions1ConditionInput
  ): Promise<CreateQuestions1Mutation> {
    const statement = `mutation CreateQuestions1($input: CreateQuestions1Input!, $condition: ModelQuestions1ConditionInput) {
        createQuestions1(input: $input, condition: $condition) {
          
          id
          number
          code
          test
          question
          choices
          timelimit
          variable
          
          
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateQuestions1Mutation>response.data.createQuestions1;
  }
  async UpdateQuestions1(
    input: UpdateQuestions1Input,
    condition?: ModelQuestions1ConditionInput
  ): Promise<UpdateQuestions1Mutation> {
    const statement = `mutation UpdateQuestions1($input: UpdateQuestions1Input!, $condition: ModelQuestions1ConditionInput) {
        updateQuestions1(input: $input, condition: $condition) {
          
          id
          number
          code
          test
          question
          choices
          timelimit
          variable
          
          
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateQuestions1Mutation>response.data.updateQuestions1;
  }
  async DeleteQuestions1(
    input: DeleteQuestions1Input,
    condition?: ModelQuestions1ConditionInput
  ): Promise<DeleteQuestions1Mutation> {
    const statement = `mutation DeleteQuestions1($input: DeleteQuestions1Input!, $condition: ModelQuestions1ConditionInput) {
        deleteQuestions1(input: $input, condition: $condition) {
          
          id
          number
          code
          test
          question
          choices
          timelimit
          variable
          
          
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteQuestions1Mutation>response.data.deleteQuestions1;
  }
  async TestsStatus(): Promise<TestsStatusQuery> {
    const statement = `query TestsStatus {
        testsStatus {
          
          test1 {
            
            done
            questions
          }
          test2 {
            
            done
            questions
          }
          test3 {
            
            done
            questions
          }
          tovarna {
            
            done
            questions
          }
          test4 {
            
            done
            questions
          }
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <TestsStatusQuery>response.data.testsStatus;
  }
  async GetAnswer(id: string): Promise<GetAnswerQuery> {
    const statement = `query GetAnswer($id: ID!) {
        getAnswer(id: $id) {
          
          id
          user
          test
          number
          code
          answer
          time
          sequence
          correct
          
          
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAnswerQuery>response.data.getAnswer;
  }
  async ListAnswers(
    filter?: ModelAnswerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAnswersQuery> {
    const statement = `query ListAnswers($filter: ModelAnswerFilterInput, $limit: Int, $nextToken: String) {
        listAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          
          items {
            
            id
            user
            test
            number
            code
            answer
            time
            sequence
            correct
            
            
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAnswersQuery>response.data.listAnswers;
  }
  async GetQuestions1(id: string): Promise<GetQuestions1Query> {
    const statement = `query GetQuestions1($id: ID!) {
        getQuestions1(id: $id) {
          
          id
          number
          code
          test
          question
          choices
          timelimit
          variable
          
          
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetQuestions1Query>response.data.getQuestions1;
  }
  async ListQuestions1s(
    filter?: ModelQuestions1FilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListQuestions1sQuery> {
    const statement = `query ListQuestions1s($filter: ModelQuestions1FilterInput, $limit: Int, $nextToken: String) {
        listQuestions1s(filter: $filter, limit: $limit, nextToken: $nextToken) {
          
          items {
            
            id
            number
            code
            test
            question
            choices
            timelimit
            variable
            
            
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListQuestions1sQuery>response.data.listQuestions1s;
  }
  OnCreateAnswerListener: Observable<
    SubscriptionResponse<OnCreateAnswerSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateAnswer {
        onCreateAnswer {
          
          id
          user
          test
          number
          code
          answer
          time
          sequence
          correct
          
          
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateAnswerSubscription>>;

  OnUpdateAnswerListener: Observable<
    SubscriptionResponse<OnUpdateAnswerSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateAnswer {
        onUpdateAnswer {
          
          id
          user
          test
          number
          code
          answer
          time
          sequence
          correct
          
          
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateAnswerSubscription>>;

  OnDeleteAnswerListener: Observable<
    SubscriptionResponse<OnDeleteAnswerSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteAnswer {
        onDeleteAnswer {
          
          id
          user
          test
          number
          code
          answer
          time
          sequence
          correct
          
          
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteAnswerSubscription>>;

  OnCreateQuestions1Listener: Observable<
    SubscriptionResponse<OnCreateQuestions1Subscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateQuestions1 {
        onCreateQuestions1 {
          
          id
          number
          code
          test
          question
          choices
          timelimit
          variable
          
          
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateQuestions1Subscription>>;

  OnUpdateQuestions1Listener: Observable<
    SubscriptionResponse<OnUpdateQuestions1Subscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateQuestions1 {
        onUpdateQuestions1 {
          
          id
          number
          code
          test
          question
          choices
          timelimit
          variable
          
          
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateQuestions1Subscription>>;

  OnDeleteQuestions1Listener: Observable<
    SubscriptionResponse<OnDeleteQuestions1Subscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteQuestions1 {
        onDeleteQuestions1 {
          
          id
          number
          code
          test
          question
          choices
          timelimit
          variable
          
          
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteQuestions1Subscription>>;
}
