<div class="testloader"  *ngIf="!load">
  <div class="loading">
    <div class="obj"></div>
    <div class="obj"></div>
    <div class="obj"></div>
    <div class="obj"></div>
    <div class="obj"></div>
    <div class="obj"></div>
    <div class="obj"></div>
    <div class="obj"></div>
  </div>
  <div class="loadertext">
    <h4>Nahrávam test</h4>
  </div>
</div>

<div class="authentication-box" *ngIf="load">
  <div class="card card-absolute">
    <div class="card-header bg-primary">
      <h5><i class="fa fa-tasks" aria-hidden="true"></i>&nbsp;{{page[counter].name}}</h5>
    </div>
    <div class="card-body" [@crossfade]="state">
      <div *ngIf="formVisible"
        [ngStyle]="{ background: 'url(' + page[counter].img + ')', 'background-repeat': 'no-repeat', 'background-size': '30%', 'background-position': '100% 85%'}">
        <div [innerHTML]="page[counter].content"></div>
        <br />
        <div class="text-infos" *ngIf='page[counter].items.length > 0'>
          <ul>
            <li *ngFor="let item of page[counter].items"><i
                class="fa fa-angle-double-right txt-primary m-r-10"></i>{{item.value}}</li>
          </ul>
        </div>
        <br />
        <div class="float-md-right">
          <button type="submit" class="btn btn-primary" (click)="onNext()" style="font-size: 17px">Další</button>
        </div>
      </div>
    </div>
  </div>
</div>