export const settings = [{
    "test": "info",
    "number": 0,
    "name": "test kompetencí pro manažerské velení",
    "content": "<div class='text-infos'> Test je zaměřen na specifické manažerské kompetence. Vyplnění testu Vám zabere přibližně 3 hodiny. Tento časový údaj je ale pouze orientační, protože většina položek v první části test u není časově řízena. test se skládá z pěti částí:</div>",
    "items": [{
      "value": "Sebehodnocení (704 položek)"
    }, {
      "value": "Práce s pojmy (max. 3 min)"
    }, {
      "value": "Číselné řady (max. 5 min)"
    }, {
      "value": "Řešení případové studie (max. 5 min)"
    }, {
      "value": "test odborných znalostí (max. 12 min)."
    }],
    "img": "../../../../assets/images/sebehodnoceni.png",
    "time": 0
    }, {
    "test": "test1",
    "number": 1,
    "name": "Sebehodnocení",
    "content": "<div class='text-infos'> Ohodnoťte každé tvrzení na škále 1 až 10 ve vztahu k vlastnímu chování či jednání, kde <b>1 znamená nejnižší míru shody</b> (dané tvrzení se mě vůbec netýká, nikdy takto nejednám) a <b>10 znamená nejvyšší míru shody</b> (toto je pro mě typické, dané tvrzení mě zcela vystihuje). Pokuste se posuzovat maximálně objektivně, jaký/jaká skutečně jste, NE jaký/jaká byste chtěl/a či měl/a být. <br/></div><br/><div class='text-infos'> Při sebehodnocení je žádoucí postupovat co nejrychleji. To, co Vás napadne jako první, bývá obvykle nejpřesnější odhad sebe sama. Některé položky jsou časově řízeny (<span class='green-text'><b>10 s</b></span>), v takových případech uvidíte odpočítávání vymezeného času. Pokud bude Vaše odpověď trvat déle než 10 s, uvidíte načítání „nadbytečných“ sekund (<span class='red-text'><b>červeně</b></span>). </div>",
    "items": [],
    "img": "",
    "time": 200
  }, {
    "test": "test2",
    "number": 2,
    "name": "Práce s pojmy",
    "content": "<div class='text-infos'>Jsou dána 3 slova. Mezi prvním a druhým slovem je určitý vztah. Mezi třetím slovem a jedním z pěti volitelných slov existuje podobný vztah. Najděte toto slovo a označte jej. Na řešení této části test u máte 3 minuty.</div>",
    "items": [],
    "img": "",
    "time": 180
  }, {
    "test": "test3",
    "number": 3,
    "name": "Číselné řady",
    "content": "<div class='text-infos'>Následující řady čísel jsou uspořádány podle určitého pravidla. Máte za úkol doplnit další číslo v každé řadě. Na řešení této části test u máte 5 minut.</div>",
    "items": [],
    "img": "",
    "time": 300
  }, {
    "test": "tovarna",
    "number": 4,
    "name": "Případová studie",
    "content": "<div class='text-infos'>viz popis situace. Na řešení této části testu máte 10 minut.</div>",
    "items": [],
    "img": "",
    "time": 600
  }, {
    "test": "test4",
    "number": 5,
    "name": "test odborných znalostí",
    "content": "<div class='text-infos'>Označte správnou odpověď na následující otázky. Na řešení této závěrečné části test u máte 12 minut.</div>",
    "items": [],
    "img": "",
    "time": 720
  }, {
    "test": "end",
    "number": 6,
    "name": "Konec",
    "content": "<div class='text-infos'>Děkujeme za vyplnění testu. Data budou nyní bezpečně uložena a zpracována.</div>",
    "items": [],
    "img": "",
    "time": 0
  }
]