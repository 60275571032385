import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { trigger, animate, style, transition  } from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'app/pages/authentication/login/authentication.service';
import { APIService } from 'app/API.service';

@Component({
  selector: 'app-tovarna',
  templateUrl: './tovarna.component.html',
  styleUrls: ['./tovarna.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('800ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('500ms ease-in', style({transform: 'translateX(-100%)'}))
      ])
    ]),
    trigger('slideRight', [
      transition(':enter', [
        style({transform: 'translateX(200%)'}),
        animate('800ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('500ms ease-in', style({transform: 'translateX(-100%)'}))
      ])
    ])
  ]
})

export class TovarnaComponent implements OnInit {
  public load = true;
  public poradi = [1, 2, 3, 4, 5, 6];
  public resultForm: FormGroup;
  public formTovarnaRank: FormGroup;
  public formTovarnaReason: FormGroup;
  public submitted = false;
  private startTime;
  private endTime;
  public visible = 'hide';
  public time: String = '';

  public timeLeft = 600;
  interval;
  timeleft2: any;
  time2: string;
  interval2: NodeJS.Timeout;

  ngOnInit() {
    this.startTime = performance.now();
  }

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private auth: AuthenticationService,
    private graphql: APIService,
    private route: ActivatedRoute) {
    this.formTovarnaRank = new FormGroup({
      first:  new FormControl('', [Validators.required]),
      second: new FormControl('', [Validators.required]),
      third:  new FormControl('', [Validators.required]),
      fourth: new FormControl('', [Validators.required]),
      fifth:  new FormControl('', [Validators.required]),
      sixth:  new FormControl('', [Validators.required])
    });

    this.formTovarnaReason = new FormGroup({
      first:  new FormControl('', [Validators.required]),
      second: new FormControl('', [Validators.required]),
      third:  new FormControl('', [Validators.required]),
      fourth: new FormControl('', [Validators.required]),
      fifth:  new FormControl('', [Validators.required]),
      sixth:  new FormControl('', [Validators.required])
    });

    this.resultForm = this.fb.group({
      formTovarnaRank: this.formTovarnaRank,
      formTovarnaReason: this.formTovarnaReason
    }, {
      validator: this.checkIfDuplicateExists
    });
    this.startTimer();
    const testTime = this.route.snapshot.queryParams.time;
      this.totalTimer(testTime ? testTime : 0);
  }

  public onNext() {
    this.submitted = true;
    if (this.resultForm.valid) {
      this.endTest();
    }
  }

  get formData() { return this.resultForm; }

  private checkIfDuplicateExists(group: FormGroup) {
    const keys = Object.keys(group.value.formTovarnaRank);
    const values = Object.values(group.value.formTovarnaRank);
    if (values.length === 6 && values.every(item => item !== '')) {
      if (new Set(values).size === values.length) {
        values.forEach((item, index) => {
            group.controls.formTovarnaRank.get(keys[index]).setErrors(null);
        });
      }  else {
        const duplicates = values.filter((item, index) => values.indexOf(item) !== index);
        group.controls.formTovarnaRank.setErrors({duplicate: true});
        values.forEach((item, index) => {
          if (duplicates.includes(item)) {
            group.controls.formTovarnaRank.get(keys[index]).setErrors({duplicate: true});
          } else {
            group.controls.formTovarnaRank.get(keys[index]).setErrors(null);
          }
        });
      }
    }
  }

  public errorCheck() {
    return Object.values(this.resultForm.value.formTovarnaReason).some(item => item === '');
  }

  private async processSuccess(values: Object) {
    this.endTime = performance.now();
    this.processResult(values).then(answer => {
      this.graphql.CreateAnswer(answer);
    });
  }

  private timeResult() {
    return Math.round(((this.endTime - this.startTime) / 1000) * 100000) / 100000;
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.time = this.timeFormat(this.timeLeft--);
      } else {
        this.endTest();
      }
    }, 1000);
  }

   private timeFormat(secs) {
    var sec_num = parseInt(secs, 10)
    var hours   = Math.floor(sec_num / 3600)
    var minutes = Math.floor(sec_num / 60) % 60
    var seconds = sec_num % 60

    return [hours,minutes,seconds]
        .map(v => v < 10 ? "0" + v : v)
        .filter((v,i) => v !== "00" || i > 0)
        .join(":")
  }

  private timeFormat2(t) {
    return ('0'+Math.floor(t/3600) % 24).slice(-2)+':'+('0'+Math.floor(t/60)%60).slice(-2)+':'+('0' + t % 60).slice(-2)
  }

  private endTest() {
    this.processSuccess(this.resultForm);
    clearInterval(this.interval);
    this.router.navigate(['test/info'], { queryParams: { page: 'tovarna' } });
  }

  private getFinalObjectForServer(answer) {
    return JSON.stringify({
      rank: answer.value.formTovarnaRank,
      reason: answer.value.formTovarnaReason
     });
  }

  private async processResult(answer) {
    const respuesta = {
      user: (await this.auth.getCurrentUser()).username,
      test: 'tovarna',
      number: '4',
      code: '4',
      answer: this.getFinalObjectForServer(answer),
      time: String(this.timeResult()),
      sequence: [],
      correct: true
    };
    return respuesta;
  }

  private totalTimer(value) {
    this.timeleft2 = value;
    this.interval2 = setInterval(() => { this.time2 = this.timeFormat2(this.timeleft2); this.timeleft2++; }, 1000);
  }
}
