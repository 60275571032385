<div class="container-fluid">
    <div class="authentication-main">
        <div class="row">
            <div class="col-md-3 p-0" *ngIf="showPage()" >
                <div class="auth-innerleft" [@slideInOut]>
                    <div class="text-center">
                        <img src="assets/images/logo-login3.png" class='logo-login' alt="">
                        <hr>
                        <address>
                            <a target="_blank" rel="noopener noreferrer" href="https://profily.vsb.cz/sch0130">Mgr. Martina Schneiderová, Ph.D.</a><br>
                            <b>email:</b> martina.schneiderova@vsb.cz<br>
                            <b>phone:</b>+420 597 321 749 <br>
                            <b>office:</b> A446
                        </address>
                        <a href="https://www.vsb.cz" target="_blank" rel="noopener noreferrer"><img src="assets/images/vsblogo.png" class='logo-vsb' alt=""></a>
                    </div>
                </div>
            </div>
            <div [ngClass]="showPage() ? 'auth-innerright background' : ''">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>