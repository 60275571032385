import { AppService } from './app.service';
import { NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app.routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { AuthGuard } from './authentication.guard';
import { PageComponent } from './pages/page/page.component';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './pages/authentication/login/login.component';
import { Test1Component } from './pages/questions/test1/test1.component';
import { InfocardComponent } from './pages/questions/infocard/infocard.component';
import { TovarnaComponent } from './pages/questions/tovarna/tovarna.component';
import { HttpClientModule } from '@angular/common/http';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { APIService } from './API.service';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    Test1Component,
    InfocardComponent,
    TovarnaComponent,
    PageComponent
  ],
  imports: [
    AmplifyAngularModule,
    BrowserModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [AuthGuard, AmplifyService, APIService, AppService],
  bootstrap: [AppComponent],
  schemas: [ NO_ERRORS_SCHEMA ]
})
export class AppModule { }
