<div class="testloader" *ngIf="load === 'info'">
  <div class="loading">
    <div class="obj"></div>
    <div class="obj"></div>
    <div class="obj"></div>
    <div class="obj"></div>
    <div class="obj"></div>
    <div class="obj"></div>
    <div class="obj"></div>
    <div class="obj"></div>
  </div>
  <div class="loadertext">
    <h4>Nahrávam test</h4>
  </div>
</div>

<ng-container *ngIf="load !== 'info'">
  <div class="box" [@show]="testPage">
    <div [ngClass]="load === 'test1' ? 'card card-absolute' : 'card card-absolute karta'">
      <div class="card-header bg-primary" *ngIf="load !== 'info'">
        <h5><i class="fa fa-comments-o" aria-hidden="true"></i>&nbsp;Otázka č.{{counter + 1}}</h5>
      </div>
      <div class="bg-secondary first-timer">
        <a class="nav-link custom-butt" aria-controls="pills-clrhome"><i class="fa fa-clock-o"
            aria-hidden="true" style="color: white !important;"></i>&nbsp;&nbsp;<span style="color: white !important;">{{time2}}</span></a>
      </div>
      <div [@crossfade]="visible" [className]="pomocna ? 'bg-success custom-nav' : 'bg-danger custom-nav'">
        <a class="nav-link custom-butt" aria-controls="pills-clrhome"><i class="fa fa-clock-o"
            aria-hidden="true" style="color: white !important;"></i>&nbsp;&nbsp;<span style="color: white !important;">{{time}}</span></a>
      </div>
      <div [@crossfade]="state" *ngIf="load === 'test1'">
        <div class="card-body" style="padding-top: 70px;">
          <p class="text"><b>{{questions[counter].question}}</b></p>
        </div>
        <div class="hodnoceni">
          <div class="form-row">
            <div class="lavy-text">
              Vůbec se mně to netýka
            </div>
            <div class="pravy-text">
              Zcela mně to vystihuje
            </div>
          </div>
          <div class="btn-group btn-group-pill" role="group" aria-label="Basic example">
            <button type="radio" class="btn btn-outline-dark" *ngFor="let number of questions[counter].choices"
              value={{number}} id="{{number}}" (click)="change($event.target.id)">
              {{number}}
            </button>
          </div>
          <div class="row">
            <div class="col-sm-2"></div>
            <div class="col-sm-10">
              <div class="float-md-left custom-row" *ngIf="error" style="color: red"><i class="fa fa-bell"
                  aria-hidden="true"></i>&nbsp;<b>{{message}}</b></div>
              <div class="float-md-right">
                <button type="submit" [disabled]="clicked" class="btn btn-primary" (click)="onNext();"
                  style="font-size: 17px">
                  <b>Další</b>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div [@crossfade]="state" *ngIf="load === 'test2'">
        <div class="card-body">
          <p class="text"><b>{{questions[counter].question}}</b></p>
        </div>
        <form #myForm="ngForm" (submit)="onNext(myForm.value)" novalidate>
          <div class="hodnoceni_test2">
            <div class="laco"><i class="fa fa-list" aria-hidden="true"></i>&nbsp;Odpovědi:</div>
            <div class="funkyradio">
              <div class="funkyradio-success" *ngFor="let choice of questions[counter].choices">
                <input type="checkbox" name="answer" id="{{choice}}" value="{{choice}}"
                  (change)="selectOnlyThis($event.target)" />
                <label for="{{choice}}">{{choice}}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2"></div>
            <div class="col-sm-10">
              <div class="float-md-left custom-row" *ngIf="error" style="color: red"><i class="fa fa-bell"
                  aria-hidden="true"></i>&nbsp;<b>{{message}}</b></div>
              <div class="float-md-right next-button">
                <button type="submit" class="btn btn-primary question-next" style="font-size: 17px">
                  <b>Další</b>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div [@crossfade]="state" *ngIf="load === 'test3'">
        <div class="card-body header-custom">
          Doplňte chybějící číslo:
          <p class="text">{{questions[counter].question}}</p>
        </div>
        <form [formGroup]="form" (ngSubmit)="onNext(form.value)" novalidate>
          <div class="hodnoceni_test3">
            <div class="form-group">
              <label class='labelos' for="exampleInputEmail1"><i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                &nbsp;Odpověď:</label>
              <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                formControlName="answer" placeholder="Sem zadejte odpověď..."
                [ngClass]="{ 'is-invalid': submitted && form.controls.answer.errors }" />
              <div *ngIf="submitted && form.controls.answer.errors" class="invalid-feedback">
                <div *ngIf="form.controls.answer.errors.required" style="font-size: 15px"><i class="fa fa-bell"
                    aria-hidden="true"></i>&nbsp;<b>Odpovězte prosím na otázku</b></div>
                <div *ngIf="form.controls.answer.errors.pattern" style="font-size: 15px"><i class="fa fa-bell"
                    aria-hidden="true"></i>&nbsp;<b>Odpověď musí být číselného formátu</b></div>
              </div>
              <small id="emailHelp" class="form-text text-muted"></small>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2"></div>
            <div class="col-sm-10">
              <div class="float-md-right next-btn">
                <button type="submit" class="btn btn-primary question-next" style="font-size: 17px">
                  <b>Další</b>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div [@crossfade]="state" *ngIf="load === 'test4'">
        <div class="card-body" style="padding-top: 70px;">
          <p class="text"><b>{{questions[counter].question}}</b></p>
        </div>
        <form #myForm="ngForm" (submit)="onNext(myForm.value)" novalidate>
          <div class="laco2"><i class="fa fa-list" aria-hidden="true"></i>&nbsp;Odpovědi:</div>
          <div class="hodnoceni_test2">
            <div class="funky">
              <div class="funky-success" *ngFor="let choice of questions[counter].choices">
                <input type="checkbox" name="answer" id="{{choice}}" value="{{choice}}"
                  (change)="selectOnlyThis($event.target)" />
                <label for="{{choice}}">{{choice}}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2"></div>
            <div class="col-sm-10">
              <div class="float-md-left customrow" *ngIf="error" style="color: red"><i class="fa fa-bell"
                  aria-hidden="true"></i>&nbsp;<b>{{message}}</b></div>
              <div class="float-md-right next-button">
                <button type="submit" class="btn btn-primary question-next" style="font-size: 17px">
                  <b>Další</b>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-container>