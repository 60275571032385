import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './pages/authentication/login/login.component';
import { AuthGuard } from './authentication.guard';

import { Test1Component } from './pages/questions/test1/test1.component';
import { InfocardComponent } from './pages/questions/infocard/infocard.component';
import { TovarnaComponent } from './pages/questions/tovarna/tovarna.component';
import { PageComponent } from './pages/page/page.component';

const routes: Routes = [{
  path: '',
  component: PageComponent, children: [
    { path: '', component: LoginComponent }
  ]
}, {
  path: 'test',
  canActivate: [AuthGuard],
  component: PageComponent, children: [
    { path: 'info', component: InfocardComponent },
    { path: 'test1', component: Test1Component},
    { path: 'test2', component: Test1Component},
    { path: 'test3', component: Test1Component},
    { path: 'test4', component: Test1Component},
    { path: 'tovarna', component: TovarnaComponent }
  ]
  }, {
    path: '**', redirectTo: '', pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes, {
        preloadingStrategy: PreloadAllModules,
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled'
      })],
  exports: [RouterModule]
})

export class AppRoutingModule {}
