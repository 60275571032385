import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './pages/authentication/login/authentication.service';
import { Observable } from 'rxjs/Observable';
import { tap } from 'rxjs/operators/tap';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthenticationService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
      return this.auth.isAuthenticated()
      .pipe(
        tap(loggedIn => {
          return this.loggedCheck(loggedIn);
        })
      );
  }

  loggedCheck(loggedIn) {
    console.log(loggedIn)
    if (!loggedIn) {
      this.router.navigate(['/']);
      return false;
    } else {
      return true;
    }
  }
}
