import { Component, OnInit, ViewEncapsulation , HostListener } from '@angular/core';
import { trigger, animate, style, transition, state } from '@angular/animations';
import { APIService } from 'app/API.service';
import { AuthenticationService } from 'app/pages/authentication/login/authentication.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { settings } from '../infocard/infocard.settings';

@Component({
  selector: 'app-questions',
  templateUrl: './test1.component.html',
  styleUrls: ['./test1.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('crossfade', [
        state('show', style({ opacity: 1 })),
        state('hide', style({ opacity: 0 })),
        transition('* => show', animate('0.4s ease-in')),
        transition('show => hide', animate('0s ease-out'))
    ]),
    trigger('show', [
      transition(':enter', [
          style({ opacity: 0 }),
          animate(500, style({ opacity: 1 }))
      ]),
    ])
  ]
})

export class Test1Component implements OnInit {
  public clicked = false;
  public load = 'info';
  private props;
  public testPage = true;
  public message = 'Zvolte prosím jednu z možností';
  public answer = '';
  public sequence: string[] = [];
  public state = 'show';
  public error = false;
  public questions = [];
  public form: FormGroup
  private startTime;
  private endTime;

  private counter = 0;

  public visible = 'hide';
  public time: string = '';
  public time2: string = '';
  public submitted = false;

  public interval;
  public interval2;
  public pomocna = true;

  private timeleft2;

  private user: String;

  constructor(
    private auth: AuthenticationService,
    private grapqhl: APIService,
    private router: Router,
    private route: ActivatedRoute
    ) {
      this.startTime = new Date();
      this.props = settings;
  }

  async ngOnInit(): Promise<void> {
    this.user = (await this.auth.getCurrentUser()).username;
    this.grapqhl.ListQuestions1s(null, 764, null).then(questions => this.processQuestions(questions));
    this.form = new FormGroup({
      answer: new FormControl('' , [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
    });
  }

  @HostListener('window:keyup', ['$event']) keyEvent(event: KeyboardEvent) {
    const key = event.key;
    if (key === '0') {
      this.change('10');
    }
    if (this.load === 'test1') {
      if (this.questions[this.counter]['choices'].includes(key)) {
        this.change(key);
      }
    }
    if (key === 'Space') {
      this.onNext();
    }
  }

  private processQuestions(questions) {
    const getTest = this.route.snapshot.queryParams.test;
    const test = getTest ? getTest : this.router.url.split('/')[2];
    this.counter = Number(this.route.snapshot.queryParams.counter);
    this.questions = questions.items.filter(k => k.test === test).sort((a, b) => a.number - b.number);
    setTimeout(() => {
      this.load = test;
      this.loadTimer(test);
    }, 3000);
  }

  private loadTimer(test) {
    if (test !== 'test1') {
      const time = this.props.filter(item => item.test === test)[0].time;
      this.startTimer({test: test, time: time});
      const testTime = this.route.snapshot.queryParams.time;
      this.totalTimer(testTime ? testTime : 0);
    }
    if (test === 'test1') {
      this.totalTimer(0);
    }
  }

  private questionController(test) {
    this.errorCheck();
  }

  private hideAndShow(test) {
    if (test === 'test1' && this.questions[this.counter].timelimit === 'Ø') {
      this.stopTimer();
    }
    this.sleep(400).then(() => {
      this.state = 'show';
      this.setCounter(test);
    });
  }

  private setCounter(test): void {
    this.counter++;
    if (test === 'test1' && this.counter < this.questions.length) {
      if (this.questions[this.counter].timelimit === 'Ø' ) {
        this.startTimer({ test: test, time: 10 });
      }
    }
    if (this.counter === this.questions.length) {
      this.stopTimer();
      clearInterval(this.interval2);
      this.router.navigate(['test/info'], { queryParams: { page: this.load, time: this.timeleft2 } });
      this.triggerChecker();
      this.counter = 0;
    }
  }

  private controlTest() {
    this.submitted = true;
    if (this.form.valid) {
      this.answer = this.form.controls.answer.value;
      this.processSuccess();
    }
  }

  private triggerChecker() { 
    if (this.load === 'test4') {
      this.auth.lambdaTrigger();
    }
  }

  private errorCheck() {
    if (this.error) {
      this.error = false;
    }
  }

  private onNext()  {
    this.load !== 'test3' ? this.answerControl() : this.controlTest();
  }

  private answerControl() {
    (this.answer === '') ? this.processError() : this.processSuccess();
  }

  private processError() {
    this.error = true;
  }

  private sendAnswerToServer(data) {
    this.grapqhl.CreateAnswer(data)
    .then(result => {
      console.log(result);
      this.hideAndShow(result.test);
    })
    .catch(e => {
      console.log(e);
    });
  }

  private checkCorrectAnswer() {
    return this.questions[this.counter]['code'] === this.answer;
  }

  private processTime() {
    return (this.endTime - this.startTime) / 1000;
  }

  private sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private processSuccess() {
    this.state = 'hide';
    this.endTime = new Date();
    this.clicked = true;
    let result = this.processResult();
    this.sendAnswerToServer(result);
    this.startTime = new Date();
    this.questionController('test1');
    this.load !== 'test3' ? this.cleanCheckedButtons() : this.resetForm();
  }

  private resetForm() {
    this.form.reset();
    this.submitted = false;
  }

  private processResult() {
    const respuesta = {
      user: this.user,
      test: this.questions[this.counter]['test'],
      number: this.questions[this.counter]['number'],
      code: this.questions[this.counter]['code'],
      answer: this.answer,
      time: this.processTime(),
      sequence: this.sequence,
      correct: this.checkCorrectAnswer()
    };
    this.sequence = [];
    return respuesta;
  }

  public change(key: string) {
    if (this.answer !== key) {
      this.sequence.push(key);
      (this.answer !== '') ? this.onCheckedButton(key) : this.withoutCheckedButton(key);
    }
  }

  private onCheckedButton(key: string) {
    this.addCheckToElement(key);
    document.getElementById(this.answer).innerText = this.answer;
    this.answer = key;
  }

  private withoutCheckedButton(key: string) {
    this.answer = key;
    this.addCheckToElement(key);
  }

  private addCheckToElement(key: string) {
    document.getElementById(key).innerText = '\u2713';
  }

  private cleanCheckedButtons() {
    if (this.load === 'test1') {
      if (document.getElementById(this.answer) !== null) {
        document.getElementById(this.answer).innerText = this.answer;
      }
    } else {
      const answerBox = document.getElementsByName('answer');
      Array.prototype.forEach.call(answerBox, element => {
        element.checked = false;
      });
    }
    this.clicked = false;
    this.answer = '';
  }

  public selectOnlyThis(event) {
    this.answer = String(this.questions[this.counter]['choices'].indexOf(event.value));
    const answerBox = document.getElementsByName('answer');
    Array.prototype.forEach.call(answerBox, element => {
      element.checked = false;
    });
    event.checked = true;
  }

  private stopTimer() {
    clearInterval(this.interval);
    this.visible = 'hide';
    this.pomocna = true;
  }

  private startTimer(value) {
    let timeleft = value.time;
    this.interval = setInterval(() => {
      this.visible = 'show';
      this.time = this.timeFormat2(timeleft);
      if (this.pomocna === true && timeleft !== 0) {
         timeleft--;
      } else {
        timeleft++;
        this.pomocna = false;
        if (value.test !== 'test1') {
          this.endTest(value.test);
        }
      }
    }, 1000);
  }

  private endTest(test) {
    clearInterval(this.interval);
    clearInterval(this.interval2);
    this.router.navigate(['test/info'], { queryParams: { page: test, time: this.timeleft2 } });
    this.triggerChecker();
  }

  private timeFormat2(t) {
    return ('0'+Math.floor(t/3600) % 24).slice(-2)+':'+('0'+Math.floor(t/60)%60).slice(-2)+':'+('0' + t % 60).slice(-2)
  }

  private totalTimer(value) {
    this.timeleft2 = value;
    this.interval2 = setInterval(() => { this.time2 = this.timeFormat2(this.timeleft2); this.timeleft2++; }, 1000);
  }
}
