import { TestsStatusQuery } from './../../../API.service';
import { AppService } from './../../../app.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { trigger, animate, style, transition, state } from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { settings } from './infocard.settings';

@Component({
  selector: 'app-infocard',
  templateUrl: './infocard.component.html',
  styleUrls: ['./infocard.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('show', [
      transition(':enter', [
          style({ opacity: 0 }),
          animate(500, style({ opacity: 1 }))
      ])
    ]),
    trigger('crossfade', [
      state('show', style({ opacity: 1 })),
      state('hide', style({ opacity: 0 })),
      transition('* => show', animate('1s ease-in')),
      transition('show => hide', animate('0.3s ease-out'))
  ])
  ]
})
export class InfocardComponent implements OnInit {
  public formVisible = true;
  public counter = 0;
  public page;
  public load = false;
  public state = 'show';

  private timer;

  private preloadedUserData: TestsStatusQuery = {};

  constructor(
    private tests: AppService,
    private router: Router,
    private route: ActivatedRoute
    ) { }

  async ngOnInit(): Promise<void> {
    this.page = settings;
   


    this.route.queryParams.subscribe(data => {
        if (Object.keys(data).length > 0) {
          this.timer =  data.time;
          this.processPage(data);
        } else {
          this.tests.getUserTestInfo().then(test => {
            if(test.test === 'test1' && test.questions === 0) {
              this.counter = 0;
            } else {
              let testData = settings.filter(info => info.test === test.test)[0];
              this.counter = testData.number;
            }
            this.preloadedUserData = this.tests.test;
            this.load = true;
          });
        }
    });
  }

  private processPage(data): void {
    console.log(data);
    const pagina = settings.filter(item => item.test === data.page)[0];
    this.counter = pagina.number + 1;
    this.load = true;
  }

  private updatePageController() {
    const test = settings[this.counter];
    if (test['test'] === 'end') {
      localStorage.clear();
      this.router.navigate(['auth/login']);
    }
    if (test['test'] !== 'end' && test['test'] !== 'info') {
      this.router.navigate([`test/${test['test']}`], { 
        queryParams: {
          test: test['test'],
          time: this.timer,
          counter: this.preloadedUserData[test['test']]?.questions ? this.preloadedUserData[test['test']]?.questions : 0
        }
      });
    }
  }

  public hideAndShow() {
    this.state = 'hide';
    this.sleep(400).then(() => {
      this.counter++;
      this.state = 'show';
    });
  }

  public onNext(): void {
    this.updatePageController();
    this.hideAndShow();
  }

  private sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
