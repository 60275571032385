<div class="authentication-box" [@show]="login" *ngIf="page === 'login'">
    <h4>Dotazník manažerské kvality</h4>
    <div class="card mt-4 p-4 mb-0">
        <form [formGroup]="loginForm" (ngSubmit)="onLogin(loginForm.value);" fxFlex="80" fxFlex.gt-sm="30" fxFlex.sm="60">
            <div class="form-group">
                <label class="col-form-label">Email</label>
                <input type="text" class="form-control" placeholder="Email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && loginForm.controls.email.errors }" />
                <div *ngIf="submitted && loginForm.controls.email.errors" class="invalid-feedback">
                    <div *ngIf="loginForm.controls.email.errors?.required">Email is required!</div>
                    <div *ngIf="loginForm.controls.email.hasError('invalidEmail')">Invalid email address!</div>
                    <div *ngIf="loginForm.controls.email.hasError('incorrect')">Invalid email address or password!</div>
                </div>
            </div>
            <div class="form-group">
                <label class="col-form-label">Heslo</label>
                <input type="password" class="form-control" placeholder="Heslo" formControlName="password" [ngClass]="{ 'is-invalid': submitted && loginForm.controls.password.errors }" />
                <div *ngIf="submitted && loginForm.controls.password.errors" class="invalid-feedback">
                    <div *ngIf="loginForm.controls.password.errors?.required">Password is required!</div>
                    <div *ngIf="loginForm.controls.password.hasError('minlength')">Password isn't long enough, minimum of 6 characters!</div>
                    <div *ngIf="loginForm.controls.password.hasError('incorrect')">Invalid email address or password!</div>
                </div>
            </div>
            <div class="form-group form-row mt-3 mb-0" style="padding-top: 10px">
                <div class="col-md-3">
                    <button type="submit" class="btn btn-primary">Přihlásit</button>
                </div>
                <div class="col-md-3">
                    <button type="button" class="btn btn-secondary" (click)="switchPage('registration')">Registrace</button>
                </div>
            </div>
            <div class="form-group form-row mt-3 mb-0" style="padding-top: 5px">
                <div class="col-md-7">
                  <button type="button" class="btn btn-link" (click)="switchPage('forget')">Zapomněli jste své heslo?</button>
                </div>
            </div>
          </form>
    </div>
</div>

<div class="container-fluid" *ngIf="page === 'reset'">
    <div class="authentication-main">
        <div class="row">
            <div class="col-md-8 p-0">
                <div class="auth-innerright">
                    <div class="authentication-box">
                        <h3>Zmena hesla</h3>
                        <div class="card mt-4 p-4">
                            <form [formGroup]="resetForm" (ngSubmit)="onReset(resetForm.value);" fxFlex="80" fxFlex.gt-sm="30" fxFlex.sm="60">
                                <h5 class="f-16 mb-3">Vytvořit nové heslo</h5>
                                <div class="form-group">
                                    <label class="col-form-label">Zadajte nové heslo</label>
                                    <input type="password" class="form-control" placeholder="Nové heslo" formControlName="password" [ngClass]="{ 'is-invalid': submitted && resetForm.controls.password.errors }" />
                                    <div *ngIf="submitted && resetForm.controls.password.errors" class="invalid-feedback">
                                        <div *ngIf="resetForm.controls.password.errors?.required">Password is required</div>
                                        <div *ngIf="resetForm.controls.password.hasError('minlength')">Password isn't long enough, minimum of 6 characters</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-form-label">Opakujte nové heslo</label>
                                    <input type="password" class="form-control" placeholder="Potvrdit heslo" formControlName="confirm" [ngClass]="{ 'is-invalid': submitted && resetForm.controls.confirm.errors }" />
                                    <div *ngIf="submitted && resetForm.controls.confirm.errors" class="invalid-feedback">
                                        <div *ngIf="resetForm.controls.confirm.errors?.required">Password is required</div>
                                        <div *ngIf="resetForm.controls.confirm.hasError('minlength')">Password isn't long enough, minimum of 6 characters</div>
                                    </div>
                                </div>
                                <div class="form-group form-row mb-0">
                                    <div class="col-md-2">
                                        <button type="submit" class="btn btn-secondary">Potvrdit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="page === 'registration'">
    <div class="authentication-box" [@show]="formTrans">
        <h3 class="text-left">Registrace</h3>
        <div class="card mt-4 p-4">
            <form class="theme-form" [formGroup]="registerForm" (ngSubmit)="onRegister(registerForm.value)">
                <div class="form-row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="col-form-label">Jméno</label>
                            <input type="text" class="form-control" placeholder="Jméno" formControlName="given_name" [ngClass]="{ 'is-invalid': submitted && registerForm.controls.given_name.errors }" />
                            <div *ngIf="submitted && registerForm.controls.given_name.errors" class="invalid-feedback">
                                <div *ngIf="registerForm.controls.given_name.errors.required">Vyplňte prosím toto pole.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="col-form-label">Příjmení</label>
                            <input type="text" class="form-control" placeholder="Příjmení" formControlName="family_name" [ngClass]="{ 'is-invalid': submitted && registerForm.controls.family_name.errors }" />
                            <div *ngIf="submitted && registerForm.controls.family_name.errors" class="invalid-feedback">
                                <div *ngIf="registerForm.controls.family_name.errors.required">Vyplňte prosím toto pole.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="col-form-label">Email</label>
                            <input type="text" class="form-control" placeholder="Email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && registerForm.controls.email.errors }" />
                            <div *ngIf="submitted && registerForm.controls.email.errors" class="invalid-feedback">
                                <div *ngIf="registerForm.controls.email.errors.required">Vyplňte prosím toto pole.</div>
                                <div *ngIf="registerForm.controls.email.errors.invalidEmail">Neplatný formát e-mailové adresy.</div>
                                <div *ngIf="registerForm.controls.email.errors.duplicityError">E-mailová adresa je již registrovaná v systému.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="col-form-label">Profese</label>
                            <select class="form-control mb-1" formControlName="custom:profession" [ngClass]="{ 'is-invalid': submitted && registerForm.controls['custom:profession'].errors }">
                                <option value="" hidden>Profese</option>
                                <option *ngFor="let item of professions;">{{item}}</option>
                            </select>
                            <div *ngIf="submitted && registerForm.controls['custom:profession'].errors" class="invalid-feedback">
                                <div *ngIf="registerForm.controls['custom:profession'].errors.required">Vyplňte prosím toto pole.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="col-form-label">Pohlaví</label>
                            <select class="form-control mb-1" formControlName="gender" [ngClass]="{ 'is-invalid': submitted && registerForm.controls.gender.errors }">
                                <option value="" hidden>Pohlaví</option>
                                <option *ngFor="let item of genders;">{{item}}</option>
                            </select>
                            <div *ngIf="submitted && registerForm.controls.gender.errors" class="invalid-feedback">
                                <div *ngIf="registerForm.controls.gender.errors.required">Vyplňte prosím toto pole.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="col-form-label">Národnost</label>
                            <select class="form-control mb-1" formControlName="locale" [ngClass]="{ 'is-invalid': submitted && registerForm.controls.locale.errors }">
                                <option value="" hidden>Národnost</option>
                                <option *ngFor="let item of countries;">{{item.name}}</option>
                            </select>
                            <div *ngIf="submitted && registerForm.controls.locale.errors" class="invalid-feedback">
                                <div *ngIf="registerForm.controls.locale.errors.required">Vyplňte prosím toto pole.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                        <label class="col-form-label">Rok narození</label>
                        <select class="form-control mb-1" formControlName="custom:year" [ngClass]="{ 'is-invalid': submitted && registerForm.controls['custom:year'].errors }">
                            <option value="" hidden>Rok</option>
                            <option *ngFor="let item of getYears();">{{item}}</option>
                        </select>
                        <div *ngIf="submitted && registerForm.controls['custom:year'].errors" class="invalid-feedback">
                            <div *ngIf="registerForm.controls['custom:year'].errors.required">Vyplňte prosím toto pole.</div>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="col-form-label">Heslo</label>
                            <input type="password" class="form-control" placeholder="Heslo" formControlName="password" [ngClass]="{ 'is-invalid': submitted && (registerForm.controls.password.errors)}" />
                            <div *ngIf="submitted && registerForm.controls.password.errors" class="invalid-feedback">
                                <div *ngIf="registerForm.controls.password.errors.required">Vyplňte prosím toto pole.</div>
                                <div *ngIf="registerForm.controls.password.errors.minlength">Minimální délka hesla je 6 písmen.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="col-form-label">Potvrdte heslo</label>
                            <input type="password" class="form-control" placeholder="Potvrdte heslo" formControlName="confirm" [ngClass]="{ 'is-invalid': submitted && (registerForm.controls.confirm.errors)}" />
                            <div *ngIf="submitted && registerForm.controls.confirm.errors" class="invalid-feedback">
                                <div *ngIf="registerForm.controls.confirm.errors.required">Vyplňte prosím toto pole.</div>
                                <div *ngIf="registerForm.controls.confirm.errors.minlength">Minimální délka hesla je 6 písmen.</div>
                                <div *ngIf="registerForm.controls.confirm.hasError('notSame')">Zadaná hesla nejsou stejná.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-sm-4"></div>
                    <div class="col-sm-4">
                        <button type="submit" class="btn btn-secondary w-100">Registrovat</button>
                    </div>
                    <div class="col-sm-4"></div>
                </div>
                <div class="form-divider"></div>
                <button type="button" class="btn btn-outline-primary" (click)="switchPage('login');"><i class="fa fa-arrow-left" aria-hidden="true"></i> Login</button>
            </form>
        </div>
    </div>
</div>

<div *ngIf="page === 'confirmation'">
    <div class="authentication-box" [@show]="formTrans">
        <h3>Ověření uživatele</h3>
        <div class="card mt-3 p-4 mb-0">
            <form [formGroup]="confirmationForm">
                <div class="form-row">
                  <div class="col-md-4">
                    <button type="button" class="btn btn-link" (click)="switchPage('login')"><i class="fa fa-arrow-left" aria-hidden="true"></i> Login</button>
                  </div>
                </div>
                <div class="form-group" style="padding-top: 8px">
                    <label class="col-form-label" [ngStyle]="{'color': black}">Pro potvrzení registrace zadajte kód, který Vám byl odeslaný na email.</label>
                    <input type="text" class="form-control" placeholder="Sem vložte získaný kód..." formControlName="email" [ngClass]="{ 'is-invalid': submitted && confirmationForm.controls.email.errors }" />
                    <div *ngIf="submitted && confirmationForm.controls.email.errors" class="invalid-feedback">
                        <div *ngIf="confirmationForm.controls.email.errors.required" style="font-size: 15px">Vyplňte prosím toto pole.</div>
                        <div *ngIf="confirmationForm.controls.email.errors.invalidEmail" style="font-size: 15px">Neplatný formát e-mailové adresy.</div>
                        <div *ngIf="confirmationForm.controls.email.errors.codeError" style="font-size: 15px">Neplatný kód.</div>
                    </div>
                    <div class="form-row mt-3 mb-0">
                        <button type="button" class="btn btn-link" (click)="resendConfirmationCode();">Kód Vám nepřišel? Kliknutím kód odešlete znovu.</button>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-4">
                        <button type="submit" class="btn btn-primary align-middle" (click)="sendConfirmationCode()">Potvrdit</button>
                    </div>
                    <div class="col-md-8">
                        <p *ngIf = "errorCode === 'success'" class="float-right notify" style="color: green"><i class="fa fa-bell" aria-hidden="true"></i>&nbsp;<b>Kód byl úspěšně odeslán do Vaší emailové schránky.</b></p>
                        <p *ngIf = "errorCode === 'error'"   class="float-right notify" style="color: red"><i class="fa fa-bell" aria-hidden="true"></i>&nbsp;<b>Odeslaní nebylo úspěšné, zkuste to znovu.</b></p>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div *ngIf="page === 'forget'">
    <div class="authentication-box" [@show]="formTrans">
        <h3>Resetování hesla</h3>
        <div class="card mt-3 p-4 mb-0">
          <div>
            <form [formGroup]="forgetForm">
                <div class="form-row">
                  <div class="col-md-4">
                    <button type="button" class="btn btn-link" (click)="switchPage('login')"><i class="fa fa-arrow-left" aria-hidden="true"></i> Login</button>
                  </div>
                </div>
                <div class="form-group" style="padding-top: 8px">
                    <label class="col-form-label">Pro resetování hesla zadejte Vaši e-mailovú adresu:</label>
                    <input type="text" class="form-control" placeholder="Příklad: franta.petarda@vsb.cz" formControlName="email" [ngClass]="{ 'is-invalid': submitted && forgetForm.controls.email.errors }" />
                    <div *ngIf="submitted && forgetForm.controls.email.errors" class="invalid-feedback">
                        <div *ngIf="forgetForm.controls.email.errors.required" style="font-size: 15px">Vyplnte prosím tohle pole.</div>
                        <div *ngIf="forgetForm.controls.email.hasError('userNotFound')">Uživatel nebyl nalezen v systému.</div>
                        <div *ngIf="forgetForm.controls.email.errors.invalidEmail" style="font-size: 15px">Neplatný formát e-mailové adresy.</div>
                        <div *ngIf="forgetForm.controls.email.hasError('limitExceed')" style="font-size: 15px">Byl překročen limit, zkuste to opět po nějaké době.</div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-4">
                        <button type="submit" class="btn btn-primary" (click)="onForget(forgetForm.value)">Reset hesla</button>
                    </div>
                </div>
            </form>
          </div>
        </div>
    </div>
</div>

<div class="container-fluid" *ngIf="page === 'verification'">
    <div class="authentication-box">
        <h3>Změna hesla</h3>
        <div class="card mt-4 p-4 mb-0">
            <form [formGroup]="verifForm" (ngSubmit)="onVerification(verifForm.value);" fxFlex="80" fxFlex.gt-sm="30" fxFlex.sm="60">
                <div class="row">
                    <div class="col-4">
                        <h5 class="f-16 mb-3">Vytvořit nové heslo</h5>
                    </div>
                    <div class="col-5"></div>
                    <div class="col-3 float-right">
                        <button type="button" class="btn btn-outline-primary float-right close-button align-top" (click)="switchPage('login');"><i class="fa fa-times" aria-hidden="true"></i> Zavřít</button>
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-form-label">Potvrzovací kód, který byl odeslán na Váš mail:</label>
                    <input type="text" class="form-control" placeholder="Potvrzovací kód" formControlName="code" [ngClass]="{ 'is-invalid': submitted && verifForm.controls.code.errors }" />
                    <div *ngIf="submitted && verifForm.controls.code.errors" class="invalid-feedback">
                        <div *ngIf="verifForm.controls.code.errors?.required">Vyplnte prosím tohle pole.</div>
                        <div *ngIf="verifForm.controls.code.errors.codeError">Neplatný kód.</div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-form-label">Zadajte nové heslo:</label>
                    <input type="password" class="form-control" placeholder="Nové heslo" formControlName="password" [ngClass]="{ 'is-invalid': submitted && verifForm.controls.password.errors }" />
                    <div *ngIf="submitted && verifForm.controls.password.errors" class="invalid-feedback">
                        <div *ngIf="verifForm.controls.password.errors?.required">Vyplnte prosím tohle pole.</div>
                        <div *ngIf="verifForm.controls.password.hasError('minlength')">Minimální počet znaků je 6.</div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-form-label">Opakujte nové heslo:</label>
                    <input type="password" class="form-control" placeholder="Potvrdit heslo" formControlName="confirm" [ngClass]="{ 'is-invalid': submitted && verifForm.controls.confirm.errors }" />
                    <div *ngIf="submitted && verifForm.controls.confirm.errors" class="invalid-feedback">
                        <div *ngIf="verifForm.controls.confirm.errors?.required">Vyplnte prosím tohle pole.</div>
                        <div *ngIf="verifForm.controls.confirm.hasError('minlength')">Minimální počet znaků je 6.</div>
                        <div *ngIf="verifForm.controls.confirm.hasError('notSame')">Zadaná hesla nejsou stejná.</div>
                    </div>
                </div>
                <div class="form-group form-row mb-0 pt-10">
                    <div class="col-md-2">
                        <button type="submit" class="btn btn-secondary">Potvrdit</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div *ngIf="page === 'successConfirm'">
    <div [@show]="formTrans" class="card mt-4 p-4">
        <div class="text-center">
            <h4>Váš účet byl úspěšně aktivován!</h4>
            <h5> Nyní se můžete přihlásit do aplikace DMK.</h5>
            <hr>
              <img src="assets/images/purple-tick.png" class='logo-purple' alt="" style="width: 100%">
        </div>
        <div class="form-row" style="padding-top: 12px">
            <div class="col-2">
            </div>
            <div class="col-8 d-flex justify-content-center px-md-10">
                <button type="button" class="btn btn-primary" (click)="switchPage('login');">Přihlásit</button>
            </div>
            <div class="col-2">
            </div>
        </div>
    </div>
</div>