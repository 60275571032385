<div class="row">
  <div class="col-custom prvy" [@slideInOut]>
    <div class="card card-absolute">
      <div class="card-header bg-primary">
        <h5>Továrna na pyrotechniku</h5>
      </div>
      <div class="card-body">
        <p>V malé továrně na pyrotechniku v nevelkém českém městečku došlo k tragédii. Továrna je situována do starších
          hal dřívějšího zkrachovalého průmyslového podniku, jedná se o spletitou zástavbu budov. </p>
        <p>V době odstávky a letních dovolených probíhala rekonstrukce energetických rozvodů ve výrobní hale. Došlo k
          výbuchu kyslíkových lahví a následnému silnému požáru. V hale v té době nebylo mnoho osob, ale i tak došlo ke
          ztrátám na životech jak u dodavatelské firmy, tak u zaměstnanců údržby.</p>
        <p>Šest osob uteklo před rychle se šířícím požárem a hrozbou další exploze do malé správní budovy, která je
          obestavěna výrobní halou a skladem. Netušili, že po explozi došlo k zablokování hlavního východu z budovy a
          celého objektu továrny, včetně zatarasení nouzového východu z budovy. Při hoření v hale vzniklo velké množství
          dusivého a štiplavého kouře, který znesnadnil orientaci přeživších v budově, a vzniklo nebezpečí rozšíření
          požáru na správní budovu.</p>
        <p>Jediná možnost úniku z budovy je kabelovým tunelem, který ústí ve skladu hotových výrobků, kterým se osoby
          mohou pohybovat pouze plížením se po jednom. V tunelu se nachází velké množství předělů a zúžených míst, které
          znesnadňují postup vpřed. Vzhledem k dispozici budov hrozí, že se požár rozšíří i do skladových prostor, kde
          je nahromaděno množství vyrobené pyrotechniky. Při ústupu šestičlenné skupiny do suterénu správní budovy, kde
          je vstup do kabelového tunelu se začaly ozývat další drobné exploze v prostorách těsně přiléhajících ke
          skladu, které způsobily další zakouření malých prostor. Byli nuceni se rychle rozhodnout, v jakém pořadí se
          budou dostávat ven kabelovým tunelem. Hrozil nejen výbuch ve skladu, který by jim mohl odříznout poslední únik
          z ohňové pasti, ale také se začalo dramaticky zvyšovat riziko udušení zplodinami z hoření zejm. oxidem
          uhelnatým. </p>
        <p>Situace se stupňovala natolik rychle, že jim bylo jasné, že první dva se z tunelu dostanou, třetí se možná
          zachrání, ale zbývající tři se s vysokou pravděpodobností udusí či uhoří.</p>
        <p><b>Skupina je složena z jednotlivců, jejichž charakteristiky jsou uvedeny dále. </b></p>
        <p><b>Úkol: V přiložené očíslované tabulce (se zdůvodněním vaší volby) určete pořadí osob, ve kterém se budou
            dostávat kabelovým tunelem.</b></p>
        <h5 class='clenove'>Členové skupinky:</h5>
        <ol type="1">
          <p>
            <li><span><b>Žena</b>, zaměstnankyně továrny na pozici <b>asistentky prodeje</b>, středoškolské vzdělání,
                věk<b> 27 let</b>, váha<b> 55 kg</b>, výška <b>170 cm</b>, ráda pravidelně sportuje, hraje volejbal 1x
                týdně, svobodná, bezdětná. Udržuje stálý vztah s mužem 2, který trvá již 3 roky. Povahově je ambiciózní,
                samostatná, veselá, nepřipouští si zátěž, je vůči ní velmi odolná. Dcera muže <b>6</b>.</span></li>
          </p>
          <p>
            <li><span><b>Muž</b>, zaměstnanec továrny na pozici <b>mistra výroby</b>, vyučený, věk <b>35 let</b>, váha
                <b>75 kg</b>, výška <b>178 cm</b>, příležitostný sportovec, svobodný, 1 dítě z předchozích vztahů (věk 5
                let). Povahou je spíše zamlklý, samostatný až samotářský, stresu odolává průměrně. Dokáže se rychle
                rozhodovat, má nestandardní nápady v krizových situacích.</span></li>
          </p>
          <p>
            <li><span><b>Žena</b>, zaměstnankyně továrny na pozici <b>účetní/fakturantky</b>, středoškolské vzdělání,
                věk <b>40 let</b>, váha <b>68 kg</b>, výška <b>165 cm</b>, rozvedená, 2 děti ve věku 10 a 12 let.
                Neudržuje žádný stálý vztah, ale muž <b>4</b> ji evidentně zajímá a snaží se jej získat. Povahově je
                velmi otevřená, extravert, ráda poutá pozornost, chce být středem dění. Emočně je rozkolísaná, každou
                situaci velmi prožívá. Není sportovec, kondici si udržuje občasným turistickým výšlapem v horách, nebo
                hodinami spinningu, který by měl být pravidelně 1x týdně, což nedodržuje.</span></li>
          </p>
          <p></p>
          <p>
            <li><span><b>Muž</b>, <b>vedoucí čety dodavatelské firmy opravující energo rozvody</b>, středoškolák, věk
                <b>52 let</b>, váha <b>80 kg</b>, výška <b>183 cm</b>, dlouholetý vrcholový sportovec, v současné době
                se této činnosti věnuje jen amatérsky. Je ženatý, ale o svém soukromí nehovoří. Ví se o něm, že je velmi
                spontánní, případným vztahům se ženami je otevřený. Povahově „srandista“, rád situace zlehčuje sarkasmy.
                Ví přesně co dělat, je emočně velmi odolný, až tvrdý vůči druhým.</span></li>
          </p>
          <p>
            <li><span><b>Žena</b>, zaměstnankyně továrny na pozici <b>obchodní ředitelky</b>, vysokoškolské vzdělání,
                věk <b>50 let</b>, váha <b>67 kg</b>, výška <b>160 cm</b>, vdaná za muže <b>6</b>, je to její i jeho
                druhé manželství (toto již bezdětné), není matkou ženy <b>1</b>. Z prvního manželství má dvě děti, obě
                již dospělé, mladší syn studuje VŠ, starší dcera již pracuje a v současné době je na mateřské dovolené
                s jedním dítětem. Nesportuje, občas s mužem jezdí na kole (cca 5x ročně) na delší výlety.</span></li>
          </p>
          <p>
            <li><span><b>Muž</b>, zaměstnanec továrny na pozici <b>vedoucího nákupu</b>, středoškolák, věk <b>56
                  let</b>, váha <b>77 kg</b>, výška <b>181 cm</b>, amatérský sportovec (tenis, squash, jízda na kole),
                občas si i zazávodí v rámci amatérských pohárů ve svém bydlišti. Je podruhé ženatý, z prvního manželství
                má pouze dceru – žena <b>1</b>. Povahově je otevřený vůči druhým lidem, ale nemusí být středem
                pozornosti. Rozhoduje se váhavě, klade důraz na kvalitu rozhodnutí. Stresu odolává průměrně. </span>
            </li>
          </p>
        </ol>
      </div>
    </div>
  </div>
  <div class="col-custom">
    <div class="card card-absolute druhy" [@slideRight]>
      <div class="card-header bg-secondary">
        <h5>Řešení</h5>
      </div>
      <div class="bg-secondary firstus-timer">
        <a class="nav-link custom-butt2"><i class="fa fa-clock-o" aria-hidden="true"
            style="color: white !important;"></i>&nbsp;&nbsp;<span style="color: white !important;">{{time2}}</span></a>
      </div>
      <div class="bg-success custom-navus">
        <a class="nav-link custom-butt"><i class="fa fa-clock-o" style="color: white !important;"></i>&nbsp;&nbsp;<span
            style="color: white !important;">{{time}}</span></a>
      </div>
      <div class="card-body">
        <form class="row" [formGroup]="resultForm" (ngSubmit)="onNext()">
          <div class="col-lg-6" formGroupName="formTovarnaRank">
            <div class="row">
              <div class="col-lg-4">
                <h6><b>Pořadí</b></h6>
              </div>
              <div class="col-lg-8">
                <h6><b>Číslo člena skupiny</b></h6>
              </div>
            </div><br />
            <div class="row">
              <div class="col-lg-4"><span class="fa-stack"><strong class="fa-stack-2x">1.</strong></span></div>
              <div class="col-lg-8">
                <div class="form-group">
                  <select class="form-control" formControlName="first"
                    [ngClass]="{ 'is-invalid': submitted && resultForm.get('formTovarnaRank').get('first').errors }">
                    <option value="" disabled>Zvolte číslo člena...</option>
                    <option *ngFor="let options of poradi" [value]="options">{{options}}</option>
                  </select>
                </div>
              </div>
            </div><br />
            <div class="row">
              <div class="col-lg-4"><span class="fa-stack"><strong class="fa-stack-2x">2.</strong></span></div>
              <div class="col-lg-8">
                <div class="form-group">
                  <select class="form-control" formControlName="second"
                    [ngClass]="{ 'is-invalid': submitted && resultForm.get('formTovarnaRank').get('second').errors }">
                    <option value="" disabled>Zvolte číslo člena...</option>
                    <option *ngFor="let options of poradi" [value]="options">{{options}}</option>
                  </select>
                </div>
              </div>
            </div><br />
            <div class="row">
              <div class="col-lg-4"><span class="fa-stack"><strong class="fa-stack-2x">3.</strong></span></div>
              <div class="col-lg-8">
                <div class="form-group">
                  <select class="form-control" formControlName="third"
                    [ngClass]="{ 'is-invalid': submitted && resultForm.get('formTovarnaRank').get('third').errors }">
                    <option value="" disabled>Zvolte číslo člena...</option>
                    <option *ngFor="let options of poradi" [value]="options">{{options}}</option>
                  </select>
                </div>
              </div>
            </div><br />
            <div class="row">
              <div class="col-lg-4"><span class="fa-stack"><strong class="fa-stack-2x">4.</strong></span></div>
              <div class="col-lg-8">
                <div class="form-group">
                  <select class="form-control" formControlName="fourth"
                    [ngClass]="{ 'is-invalid': submitted && resultForm.get('formTovarnaRank').get('fourth').errors }">
                    <option value="" disabled>Zvolte číslo člena...</option>
                    <option *ngFor="let options of poradi" [value]="options">{{options}}</option>
                  </select>
                </div>
              </div>
            </div><br />
            <div class="row">
              <div class="col-lg-4"><span class="fa-stack"><strong class="fa-stack-2x">5.</strong></span></div>
              <div class="col-lg-8">
                <div class="form-group">
                  <select class="form-control" formControlName="fifth"
                    [ngClass]="{ 'is-invalid': submitted && resultForm.get('formTovarnaRank').get('fifth').errors }">
                    <option value="" disabled>Zvolte číslo člena...</option>
                    <option *ngFor="let options of poradi" [value]="options">{{options}}</option>
                  </select>
                </div>
              </div>
            </div><br />
            <div class="row">
              <div class="col-lg-4"><span class="fa-stack"><strong class="fa-stack-2x">6.</strong></span></div>
              <div class="col-lg-8">
                <div class="form-group">
                  <select class="form-control" formControlName="sixth"
                    [ngClass]="{ 'is-invalid': submitted && resultForm.get('formTovarnaRank').get('sixth').errors }">
                    <option value="" disabled>Zvolte číslo člena...</option>
                    <option *ngFor="let options of poradi" [value]="options">{{options}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4"></div>
              <div class="col-lg-8">
                <div class="float-md-left vlastny"
                  *ngIf='submitted && this.resultForm.controls.formTovarnaRank.errors?.duplicate' style="color: red"><i
                    class="fa fa-bell" aria-hidden="true"></i>&nbsp;<b>Pořadí členů musí být rozdílne.</b></div>
              </div>
            </div>
          </div>
          <div class="col-lg-6" formGroupName="formTovarnaReason">
            <div class="row">
              <div class="col-lg-12">
                <h6><b>Důvod zařazení na pozici</b></h6>
              </div>
            </div><br />
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <textarea class="form-control" formControlName="first" rows="1"
                    [ngClass]="{ 'is-invalid': submitted && resultForm.get('formTovarnaReason').get('first').errors }"></textarea>
                </div>
              </div>
            </div><br />
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <textarea class="form-control" formControlName="second" rows="1"
                    [ngClass]="{ 'is-invalid': submitted && resultForm.get('formTovarnaReason').get('second').errors }"></textarea>
                </div>
              </div>
            </div><br />
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <textarea class="form-control" formControlName="third" rows="1"
                    [ngClass]="{ 'is-invalid': submitted && resultForm.get('formTovarnaReason').get('third').errors  }"></textarea>
                </div>
              </div>
            </div><br />
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <textarea class="form-control" formControlName="fourth" rows="1"
                    [ngClass]="{ 'is-invalid': submitted && resultForm.get('formTovarnaReason').get('fourth').errors  }">
                </textarea>
                </div>
              </div>
            </div><br />
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <textarea class="form-control" formControlName="fifth" rows="1"
                    [ngClass]="{ 'is-invalid': submitted && resultForm.get('formTovarnaReason').get('fifth').errors  }"></textarea>
                </div>
              </div>
            </div><br />
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <textarea class="form-control" formControlName="sixth" rows="1"
                    [ngClass]="{ 'is-invalid': submitted  && resultForm.get('formTovarnaReason').get('sixth').errors }"></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="float-md-left vlastny" *ngIf='submitted && errorCheck()' style="color: red"><i
                    class="fa fa-bell" aria-hidden="true"></i>&nbsp;<b>Vyplnte prosím všechna pole.</b></div>
                <div class="float-md-right">
                  <button type="submit" class="btn btn-primary question-next" style="font-size: 17px">
                    <b>Next</b>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>