import { Component,  ViewEncapsulation, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from './authentication.service';
import { emailValidator } from '../../../utils/app-validators';
import { trigger, animate, style, transition } from '@angular/animations';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [AuthenticationService],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('show', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
    ])
  ]
})

export class LoginComponent implements OnInit {
  public submitted = false;
  public loginForm: FormGroup;
  public resetForm: FormGroup;
  public verifForm: FormGroup;
  public forgetForm: FormGroup;
  public registerForm: FormGroup;
  public confirmationForm: FormGroup;
  public page = 'login';
  private user: any;
  public countries;
  public errorCode = '';

  public genders = ['Muž', 'Žena'];
  public professions = [
    'Student technického oboru',
    'Student netechnického oboru',
    'Hasič',
    'Ostatní'
  ];

  // Possible server responds to login request
  private loginNavigator = {
    SMS_MFA: null,
    SOFTWARE_TOKEN_MFA: null,
    MFA_SETUP: null,
    NEW_PASSWORD_REQUIRED: (user) => {
      this.switchPage('reset');
      this.user = user;
    }
  };

  // Error handling to login request
  private errorProcessing = {
    UserNotConfirmedException: null,
    PasswordResetRequiredException: () => {
      this.forgetPass(this.loginForm.get('email').value);
    },
    NotAuthorizedException: () => {
      this.incorrectLogin();
    },
    UserNotFoundException: () => {
      this.incorrectLogin();
    },
    UsernameExistsException: () => {
      this.registerForm.controls.email.setErrors({ duplicityError: true });
    }
  };

  private loadForm = {
    login:  () => { this.loginFormValidation(); },
    reset:  () => { this.resetFormValidation(); },
    forget: () => { this.forgetFormValidation(); },
    verification: () => { this.verifFormValidation(); },
    registration: () => { this.registerFormValidation(); },
    confirmation: () => { this.confirmationFormValidation(); },
    successConfirm: () => { return; }
  };

  constructor(
    private fb: FormBuilder,
    private auth: AuthenticationService,
    private router: Router,
    private http: HttpClient) {
    this.loadForm[this.page]();
  }

  ngOnInit () {
    this.http.get<any>('/assets/data/registerCountries.json').subscribe(countries => {
      this.countries = countries;
    });
  }

  public onRegister(values): void {
    this.submitted = true;
    const mapper = this.registrationMapper(values);
    if (this.registerForm.valid) {
      this.auth.signUp(mapper).then(data => {
        this.user = data;
        console.log(data);
        this.switchPage('confirmation');
      }).catch(err => {
        console.log(err);
        this.errorProcessing[err.code]();
      });
    }
  }

  // After submitting login form, check validity of form, process data
  public onLogin(values): void {
    this.submitted = true;
    if (this.loginForm.valid) {
      this.loginUser(values);
    }
  }

  // After submitting reset password form, check validity, process data
  public onReset(values): void {
    this.submitted = true;
    if (this.resetForm.valid) {
      this.newPassword(values.confirm);
    } else {
      this.resetForm.reset();
    }
  }

  // After submitting forget password form, chceck validity, process data
  public onForget(values): void {
    this.submitted = true;
    if (this.forgetForm.valid) {
      this.forgetPass(values.email);
    }
  }

  /* validating verification form after a verification code was send to user,
  who is trying to reset his password, if password reset is successful, redirect
  to login page */
  public onVerification(values): void {
    this.submitted = true;
    console.log(values);
    if (this.verifForm.valid) {
      this.auth.forgotPasswordSubmit(this.user, values.code, values.password)
      .then(data => {
        this.switchPage('successConfirm');
      })
      .catch(err => {
        this.verifForm.controls.code.setErrors({ codeError: true });
      });
    }
  }

  public sendConfirmationCode() {
    if (this.confirmationForm.valid) {
      this.auth.confirmationCode(this.user.user.username, this.confirmationForm.value.email).then(result => {
        console.log(result);
        this.switchPage('successConfirm');
      }).catch(err => {
        console.log(err);
        this.confirmationForm.controls.code.setErrors({ codeError: true });
      });
    }
  }

  public resendConfirmationCode() {
    this.auth.resendConfirmationCode(this.user.user.username).then(result => {
      console.log(result);
      this.temporalMessageShow('success');
    }).catch(err => {
      this.temporalMessageShow('error');
      console.log(err);
    });
  }

  private temporalMessageShow(message) {
    this.errorCode = message;
    setInterval(() => { this.errorCode = ''; }, 5000);
  }

  /* function callled when user requests for password reset, a verifiction code is sent
  to him (email, phone), page is redirected to verification form */
  private forgetPass(email) {
    this.auth.forgotPassword(email).then(data => {
      this.switchPage('verification');
      this.user = email;
    })
    .catch(err => { console.log(err); this.processForgetServerResponse(err); } );
  }

  // Error handling when user was not found in database
  private processForgetServerResponse(response) {
    if (response.code === 'UserNotFoundException') {
      this.forgetForm.controls.email.setErrors({ userNotFound: true});
    }
    if (response.code === 'LimitExceededException') {
      this.forgetForm.controls.email.setErrors({ limitExceed: true});
    }
  }

  // login user with entered credentials, process server respond
  private loginUser(credentials: any) {
    this.auth.signIn(credentials).then(user => {
      this.processLoginAnswer(user);
    }).catch(err => {
      this.errorProcessing[err.code]();
    });
  }

  /* process login answer, if successful redirect to dashboard main page,
  otherwise handle error responds*/
  private processLoginAnswer(user) {
    if (user.challengeName === undefined) {
      this.router.navigate(['/test/info']);
    } else {
      this.loginNavigator[user.challengeName](user);
    }
  }

  /* if password was confirmed, redirect to main dashboard page, this function
  is called when user is signing in for the first time, when he is prompted to
  change his password in order to confirm his account */
  private newPassword(newPassword) {
    this.auth.newPassword(newPassword, this.user).then(user => {
      this.switchPage('successConfirm');
    }).catch(e => {
      // bad/weak password
      this.resetForm.reset();
    });
  }

  // function to redirect pages
  public switchPage(page) {
    this.loadForm[page]();
    this.submitted = false;
    this.page = page;
  }

  // validtor to compare password equality
  private checkPasswords(group: FormGroup) {
    const pass = group.get('confirm').value;
    const confirmPass = group.get('password').value;
    console.log(pass === confirmPass);
    if (pass === confirmPass) {
      group.get('confirm').setErrors(null);
      return null;
    } else {
      group.get('confirm').setErrors({ notSame: true});
      return  { notSame: true };
    }
  }

  // validator to check login form corectness
  private loginFormValidation() {
    this.loginForm = this.fb.group({
      email:    [null, Validators.compose([Validators.required, emailValidator])],
      password: [null, Validators.compose([Validators.required, Validators.minLength(6)])]
    });
  }

  // validator to check verification form corectness
  private verifFormValidation() {
    this.verifForm = this.fb.group({
      code:     [null, Validators.compose([Validators.required])],
      password: [null, Validators.compose([Validators.required, Validators.minLength(6)])],
      confirm:  [null, Validators.compose([Validators.required, Validators.minLength(6)])]
    }, {
      validator: this.checkPasswords
    });
  }

  // validator to check reset form corectness
  private resetFormValidation() {
    this.resetForm = this.fb.group({
      password: [null, Validators.compose([Validators.required, Validators.minLength(6)])],
      confirm:  [null, Validators.compose([Validators.required, Validators.minLength(6)])]
    }, {
      validator: this.checkPasswords
    });
  }

  // validator to check forget password form corectness
  private forgetFormValidation() {
    this.forgetForm = this.fb.group({
      email: [null, Validators.compose([Validators.required, emailValidator])],
    });
  }

  private confirmationFormValidation() {
    this.confirmationForm = this.fb.group({
      email: [null, Validators.compose([Validators.required])],
    });
  }

  private registerFormValidation() {
    this.registerForm = this.fb.group({
      given_name:    [null, Validators.compose([Validators.required, Validators.minLength(2)])],
      family_name:   [null, Validators.compose([Validators.required, Validators.minLength(2)])],
      email:         [null, Validators.compose([Validators.required, emailValidator])],
      password:      [null, Validators.compose([Validators.required, Validators.minLength(6)])],
      confirm:       [null, Validators.compose([Validators.required, Validators.minLength(6)])],
      'custom:year': [null, Validators.compose([Validators.required])],
      gender:        [null, Validators.compose([Validators.required])],
      locale:        [null, Validators.compose([Validators.required])],
      'custom:profession': [null, Validators.compose([Validators.required])]
    }, {
      validator: this.checkPasswords
    });
  }

  private registrationMapper(form) {
    return {
      username: form.email,
      password: form.password,
      attributes: {
        given_name: form.given_name,
        family_name: form.family_name,
        email: form.email,
        'custom:year': form['custom:year'],
        gender: form.gender,
        locale: form.locale,
        'custom:profession': form['custom:profession']
      }
    };
  }

  // set errors if the login was unsuccessful
  private incorrectLogin() {
    this.loginForm.controls.password.setValue('');
    this.loginForm.controls.password.setErrors({ incorrect: true});
  }

  public getYears() {
    const years = [];
    const recent = new Date().getFullYear();
    for (let year = recent; year >= 1900; year--) {
        years.push(year);
    }
    return years;
  }
}
